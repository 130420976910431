import Swiper from 'swiper/bundle';
let swiper = new Swiper(".main-slider", {
  speed: 3000,
  autoplay: {
    delay: 3000,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});
